.header .logo {
  width: 120px;
  height: 40px;
  background: #eee;
  margin-right: 10px;
  overflow: hidden;
}

.header h3 {
  margin-bottom: 0;
  margin-right: 40px;
}
.header img {
  width: 120px;
  height: 40px;
}

#container {
  display: flex;
}


.siderLeft {
  height: 54px;
  line-height: 54px;
  margin-top: 50px;
  margin-left: 28px;
  border-radius: 8px 0 0 8px;
  background-color: #fff;
  color: black;
  font-size: 16px;
  font-weight: 600;
}
.Content_toptab {
  padding: 40px 0;
  
}

.Content_toptab_one {
  margin-right: 20px;
  border-radius: 4px !important;
}
.Content_toptab_one::before {
  display: none !important;
}
.Content_tit {
  font-size: 20px;
  font-weight: 600;

}

.Content_box {
    background-color: #fff;
    margin: 20px;
    border-radius: 10px;
    color: #000;
    padding-top: 20px;
    text-align: left;
    padding-left: 30px;
    height: 800px;
    overflow-y: scroll;
}

.Content_tabel {
  margin-right:  180px;
}
.paginationStyle {
  text-align: right;
  padding: 20px 200px;
}
.bottom {
  height: calc( 100vh - 68px );
  background-color: #fff;
}

