.order_card {
	min-height: 70px;
	border: 1px solid #eee;
	display: flex;
	justify-content: center;
	border-radius: 10px;
	margin-bottom: 20px;
	cursor: pointer;
}

.order_main {
	width: 94%;
	/* border: 1px solid green; */
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	padding: 10px;
}

.order_inner {
	width: 100%;
}

.order_inner_flex {
	width: 100%;
	display: flex;
	justify-content: space-between;
}

.my-custom-class  {
	width: 600px!important;
	height: 800px!important;
	
}

.order_inner_upper {

	font-weight: bold;
	font-size: 16px;
}

.order_inner_sub {
	width: 100%;
	font-size: 14px;
	color: #969696;
}

.title_des {
	/* border:1px solid blue; */
	padding: 10px 10px 0;
}

.info_des {
	display: flex;
	padding: 15px 10px;
	border: 1px solid #eee;
	background: #F3F3F3;
	border-radius: 10px;
}

.info_right {
	width: 100%;
	margin-left: -112px;
}

.info_left {
	display: flex;
	width: 11%;
	font-style: bold;
	/* border: 1px solid green; */
	justify-content: center;
	align-items: center;
	flex-direction: column;
	margin-right: 40px;
}

.info_left_title {
	font-weight: bold;
}

.add_person {
	display: flex;
	width: 100%;
	justify-content: flex-end;
	align-items: center;
	color: #14CC1B;
	padding: 10px;
}

.add_person_text {
	font-weight: bold;
	font-size: 17px;
}

.title_recommend {
	padding: 10px;
	color: #969696;
}

.special_item {
	width: 200px;
}

.title_space {
	font-weight: bold;
	padding: 10px;
	margin-bottom: 20px;
	border-bottom: 1px solid #eee;
	/* border: 1px solid green; */
}

:where(.css-dev-only-do-not-override-ph9edi).ant-col-24 {
	display: flex;
	flex: 0 0 100%;
	max-width: 100%;
}

.name_spe {
	display: flex;
	align-items: center;
}

.contact_path {
	margin: 10px;
}

.name_result {
	color: #3AA0FF;
}

.business_des {
	color: #9C9C9C;
	padding: 10px;
}

.module_title {
	/* border: 1px solid green; */
	margin-bottom: 30px;
}

.des_suggest {
	color: #9C9C9C;
}

.userInfo {
	display: flex;
	justify-content: flex-start;
	align-items: stretch;
	border-radius: 8px;
	overflow: hidden;
	margin-bottom: 16px;
	position: relative;
}

.userInfo .userNo {
	width: 60px;
	background-color: #B0B0B0;
	display: flex;
	justify-content: center;
	align-items: center;
}

.userInfo .userDetails {
	padding: 24px 8px 8px;
	flex-grow: 1;
	background-color: #F3F3F3;
}

.userInfo .deleteStyle {
	position: absolute;
	right: -8px;
	top: 0px;
}

.ant-upload-list-item-actions a {
	display: none;
}

.tips {
	padding: 0 24px 16px;
}

.tips p {
	color: #9C9C9C;
	font-size: 12px;
}
.item-tips{
	position: absolute;
	bottom: 4px;
	left: 33.3%;
	color: #9C9C9C;
	font-size: 12px;
}

.nameStyle {
	width: 98%;
	display: flex;
	align-items: end;
	padding: 8px 0 12px;
}

.nameStyle label {
	width: 24px;
	text-align: right;
	padding-right: 8px;
}

.translationWizard {
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

.translationWizard .button {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	font-size: 22px;
	color: #1677ff;
	margin: -24px 24px 0;
	padding: 4px 12px;
	border: 1px solid #1677ff;
	border-radius: 8px;
	cursor: pointer;
}

.tipsMessage {
	color: #9C9C9C;
	font-size: 12px;
	margin-left: 33.33%;
}
.upload-file .ant-upload-wrapper{
	display: flex;
}
.explain{
	position: relative;
	top: -23px;
	left: 25%;
	height:0;
	color:#999;
	font-size: 13px;
}
.has-explain:has(.ant-form-item-explain-error) .explain {
	top: -3px;
	height: auto;
}

.ant-col {
	text-align: left!important;
	margin-left: 20px;
}

.qdP {
	text-align: center;
	font-size: 22px;
	font-weight: 600;
}
.qdC {
	font-size: 20px;
	text-align: center;
}

.qdpimgt {
	width: 216px;
	height: 117px;
	text-align: center;
}

.qdpcode {
	width: 280px;
	height: 280px;
	display: flex;
	justify-content: center;
}