.body {
  width: 100%;
  height: 100%;
  /* background: linear-gradient(90deg, #C7C5F4, #776BCC); */
  background-image: url("../../image/kv-\(5\).png");

  overflow: hidden;
}

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}

.screen {
  background: #ffffff;
  position: relative;
  left: 300px;

  width: 400px;
  box-shadow: 0px 0px 24px #5C5696;
  border-radius:8px;
}

.screen__content {
  z-index: 1;
  position: relative;
  height: 100%;
}

.screen__background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
  -webkit-clip-path: inset(0 0 0 0);
  clip-path: inset(0 0 0 0);
}

.screen__background__shape {
  transform: rotate(45deg);
  position: absolute;
}

.screen__background__shape1 {
  height: 520px;
  width: 520px;
  background: #FFF;
  top: -50px;
  right: 120px;
  border-radius: 0 72px 0 0;
}

.screen__background__shape2 {
  height: 220px;
  width: 220px;
  background: #6C63AC;
  top: -172px;
  right: 0;
  border-radius: 32px;
}

.screen__background__shape3 {
  height: 540px;
  width: 190px;
  background: linear-gradient(270deg, #5D54A4, #6A679E);
  top: -24px;
  right: 0;
  border-radius: 32px;
}

.screen__background__shape4 {
  height: 400px;
  width: 200px;
  background: #7E7BB9;
  top: 420px;
  right: 50px;
  border-radius: 60px;
}

.login {
  padding: 30px;
  padding-top: 32px;
  padding-bottom: 60px;
}
.screen__title {
  text-align: center;
    color: rgba(64, 158, 255, .8);
    font-weight: 600;
    margin-top: 60px;
    margin-bottom: 10px;
    height: 32px;
    font-size: 28px;
    line-height: 32px;

}

.login__field {
  padding-top: 20px;
  position: relative;
}
.screen__content .ant-input-affix-wrapper{
  padding-top: 7px;
  padding-bottom: 7px;
  height: 50px;
  border-radius:50px;
  background-color: #f7f7f7;
}
.screen__content .ant-input{
  padding-top: 5px;
  padding-bottom: 5px;
  background-color: #f7f7f7;

}
.login_code{
  font-size: 13px;
  cursor: pointer;
  color: #4C489D;
}

.login__input {
  border: none;
  border-bottom: 2px solid #D1D1D4;
  background: none;
  padding: 10px;
  padding-left: 32px;
  font-weight: 700;
  width: 88%;
  transition: .2s;
}

.login__input:active,
.login__input:focus,
.login__input:hover {
  outline: none;
  border-bottom-color: #6A679E;
}
.change_login_type{
  margin: 10px 0;
  height: 18px;
}
.change_login_type span{
  font-size: 13px;
  cursor: pointer;
  color: #4C489D;
}
.login__submit {
  background: #fff;
  font-size: 14px;
  margin-top: 20px;
  border: 1px solid #D4D3E8;
  text-transform: uppercase;
  width: 100%;
  color: #4C489D;
  cursor: pointer;
  text-align: center;
  background-color: #a0cfff;
  border-color: #a0cfff;
  border-radius: 2px;
  height: 40px;
}

.button__text {
  font-size: 16px;
  color: #fff;
 
}

.login__submit:active,
.login__submit:focus,
.login__submit:hover {
  border-color: #6A679E;
  outline: none;
}

.button__icon {
  font-size: 24px;
  margin-left: auto;
  color: #7875B5;
}

.social-login {
  position: absolute;
  height: 140px;
  width: 160px;
  text-align: center;
  bottom: 0px;
  right: 0px;
  color: #fff;
}

.social-icons {
  display: flex;
  align-items: center;
  justify-content: center;
}

.social-login__icon {
  padding: 20px 10px;
  color: #fff;
  text-decoration: none;
  text-shadow: 0px 0px 8px #7875B5;
}

.social-login__icon:hover {
  transform: scale(1.5);
}
input:-webkit-autofill { box-shadow: 0 0 0px 1000px #FFF inset !important;}